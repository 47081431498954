import { useDispatch } from 'react-redux'
import { userSet } from 'state/reducers/userReducer'

const useLogout = () => {
  const dispatch = useDispatch()
  const logoutHandler = async () => {
    const response = await fetch('/account/logout')
    if (response.ok) {
      dispatch(userSet({}))
      let redirectUrl = await response.text()
      window.location.href = redirectUrl
    }
  }

  return logoutHandler
}

export default useLogout
