export const apiGet = async (url, redirectToLogin = true) => {
  const response = await fetch(url)
  if (response.status === 401) {
    if (redirectToLogin) {
      window.location.href = `/account/login?redirectUrl=${window.location.pathname}`
      return null
    }
  }
  if (response.status == 403) {
    window.location.href = '/notallowed'

    return null
  }

  return response
}

export const apiPost = async (url, body, redirectToLogin = true) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  if (response.status === 401) {
    if (redirectToLogin) {
      window.location.href = `/account/login?redirectUrl=${window.location.pathname}`

      return null
    }
  }
  if (response.status == 403) {
    window.location.href = '/notallowed'

    return null
  }

  return response
}
