import useLogout from 'hooks/useLogout'
import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const Logout = React.lazy(() => import('./views/pages/Logout'))
const ErrorPage = React.lazy(() => import('./views/pages/ErrorPage'))

// Pages
const App = () => {
  const logoutHandler = useLogout()

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path='/logout' name='Logout' element={<Logout />} />
          <Route
            path='/notallowed'
            name='Not Allowed'
            element={
              <ErrorPage
                errorCode='403'
                errorMessage='Brak uprawnień'
                errorDescription={
                  <>
                    Twoje konto nie posiada uprawnień do przeglądania tej
                    strony.
                    <br />
                    Skontaktuj się z administratorem lub{' '}
                    <a className='pointer' onClick={logoutHandler}>
                      wyloguj się
                    </a>{' '}
                    i zaloguj się na inne konto.
                  </>
                }
              />
            }
          />
          <Route path='/error' name='Error' element={<ErrorPage />} />
          <Route path='*' name='Home' element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
