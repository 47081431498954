import { configureStore } from '@reduxjs/toolkit'
import dictionariesReducer from './reducers/dictionaryReducer'
import sidebarReducer from './reducers/sidebarReducer'
import toasterReducer from './reducers/toasterReducer'
import userReducer from './reducers/userReducer'

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    user: userReducer,
    toaster: toasterReducer,
    dictionaries: dictionariesReducer
  }
})

export default store
