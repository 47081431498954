import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGet } from 'helpers/apiClient'

export const fetchCategories = createAsyncThunk(
  'dicionaries/categories',
  async () => {
    const response = await apiGet('/api/categories', true)
    if (response.status === 401) window.location.reload()
    if (!response) return []

    return await response.json()
  }
)

export const fetchFormats = createAsyncThunk(
  'dicionaries/formats',
  async () => {
    const response = await apiGet('/api/formats', true)
    if (response.status === 401) window.location.reload()
    if (!response) return []

    return await response.json()
  }
)

const dictionarySlice = createSlice({
  name: 'dictionaries',
  initialState: {
    categories: [],
    formats: []
  },
  reducers: {
    categoriesSet(state, action) {
      return { ...state, categories: action.payload }
    },
    formatsSet(state, action) {
      return { ...state, formats: action.payload }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCategories.fulfilled, (state, action) => {
        return {
          ...state,
          categories: action.payload.map((e) => {
            return { id: e.id, name: e.name }
          })
        }
      })
      .addCase(fetchFormats.fulfilled, (state, action) => {
        return {
          ...state,
          formats: action.payload.map((e) => {
            return { id: e.id, name: e.name }
          })
        }
      })
  }
})

export const { categoriesSet, formatsSet } = dictionarySlice.actions
export default dictionarySlice.reducer
